
import { PaymentFilterQueryPayload } from "@/store/modules";
import Multiselect from "vue-multiselect";
import { Component, Vue } from "vue-property-decorator";

type OptionProps = { text: string; value: string };

@Component({ components: { Multiselect } })
export default class PaymentStatusDropdown extends Vue {
  filter: PaymentFilterQueryPayload = {};

  // @Model("change", { type: Array, default: () => [] })

  value: OptionProps[] = [];

  // @Model("change", { type: Number, required: false })
  mappedTags: string[] = [];

  options: Array<OptionProps> = [
    {
      text: "Approved",
      value: "approved",
    },
    {
      text: "Voided",
      value: "voided",
    }
  ];

  // optionIndex: Map<number, PartCategory> = new Map();

  // mounted() {
  //   this.load();
  // }

  // load() {
  //   this.$nextTick(() => this.fetchRender(this.compilePayload()));
  // }

  // async fetchRender(payload: PartCategoryAllPayload) {
  //   await PartCategory.dispatch("all", payload);
  //   this.updateOptions();
  // }

  // updateOptions(): Array<any> {
  //   const data = PartCategory.query().withAll().get();

  //   this.options = data.map((cat) => ({
  //     text: cat.name,
  //     value: cat.id,
  //   }));

  //   this.optionIndex = new Map(data.map((cat) => [cat.id, cat]));

  //   return this.options;
  // }

  // compilePayload(
  //   merge: Partial<PartCategoryAllPayload> = {}
  // ): PartCategoryAllPayload {
  //   return {
  //     ...{ filter: filterEmptyQuery(this.filter) },
  //     ...merge,
  //   };
  // }

  get availableOptions() {
    return this.options;
    // console.log(this.value, "val");
    // return this.options.filter((opt) => this.value.indexOf(opt.text) === -1);
  }

  // get loading() {
  //   return !!PartCategory.store().state.entities.partCategory.fetching;
  // }

  onSelect(selectedOption: OptionProps, id: number) {
    // const { value } = selectedOption;
    // const opt = this.optionIndex.get(value);
    // if (opt) {
    //   // handler.change(`${opt.name}`);
    //   this.mappedTags.push(opt);
    //   this.$emit("cat-select", this.mappedTags);
    // }

    this.mappedTags.push(selectedOption.value);
    this.$emit("select", this.mappedTags);
  }

  onRemove({ value }: OptionProps) {
    const i = this.mappedTags.findIndex((t) => t === value);
    this.mappedTags.splice(i, 1);
    this.$emit("select", this.mappedTags);
  }

  // onRemove(cat: PartCategory) {
  //   const i = this.mappedTags.findIndex((t) => t.id === cat.id);
  //   this.mappedTags.splice(i, 1);
  //   this.value.splice(i, 1);
  //   // this.options = this.options.filter((opt) => this.value.indexOf(opt.text) === -1);

  //   this.$emit("cat-select", this.mappedTags);
  // }
}
