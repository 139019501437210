
import { Component, Vue, Prop } from "vue-property-decorator";
import { Payment } from "@/store/modules";
import { mixins } from "vue-class-component";
import { CurrencyFormatMixin, DateFormatMixin } from "@/mixins";
import { InfoCardLoader } from "@/components/InfoCardLoader.vue";

@Component({ components: { InfoCardLoader } })
export class PayeeDetailCard extends mixins(CurrencyFormatMixin, DateFormatMixin) {
  @Prop(Payment)
  payment!: Payment;

  loading = false;
}

export default PayeeDetailCard;
