
import { Component, Vue, Prop } from "vue-property-decorator";
import { DateFormatMixin, HeMixin, CurrencyFormatMixin } from "@/mixins";
import { mixins } from "vue-class-component";
import { Payment, Technician } from "@/store/modules";
import { PayeeDetailCard } from "./details/PayeeDetailCard.vue";
import { PaymentApproveButton } from "./details/PaymentApproveButton.vue";
import { PaymentVoidButton } from "./details/PaymentVoidButton.vue";
import { ErrorInterface } from "@/utility";

@Component({
  components: { PayeeDetailCard, PaymentApproveButton, PaymentVoidButton },
})
export class PaymentDetail extends mixins(HeMixin, CurrencyFormatMixin) {
  loading = true;

  saving = false;

  payment: Payment | null = null;

  @Prop([String, Number])
  readonly id!: string | number;

  subscription: Array<() => void> = [];

  destroyed() {
    this.subscription.forEach((fn) => fn());
  }

  created() {
    const update = this.$store.watch<ErrorInterface[]>(
      () => Payment.getErrors(),
      (val, oldVal) => {
        if (val && val.length) {
          this.$root.$bvToast.toast(`Failed to save Payment`, {
            title: "Error",
            variant: "danger",
          });
        }
      }
    );
    const saving = this.$store.watch<boolean>(
      () => Payment.isSaving(),
      (val) => {
        this.saving = val;
      }
    );
    this.subscription = [update, saving];
  }

  mounted() {
    this.$nextTick(() => {
      this.updateData();
      this.$store.watch<Payment>(
        () => Payment.getters("find")(this.id),
        () => {
          // console.log("B", newMr, oldMr);
          this.payment = this.findRecord();
        }
      );
    });
  }

  print() {
    // console.log(this.$htmlToPaper);
    this.$htmlToPaper("print-reciept");
  }

  async updateData() {
    let payment = this.findRecord();
    if (!payment) {
      this.loading = true;
      await Payment.dispatch("findOne", { id: +this.id });
      payment = this.findRecord();
      if (!payment) {
        this.loading = false;
        console.error("Unknown payment with id ", this.id);
        return;
      }
    }

    if (!payment.technician && payment.techID) {
      await Technician.dispatch("findOne", {
        id: +payment.techID,
      });
      payment = this.findRecord();
    }

    this.payment = payment;
    this.loading = false;
  }

  findRecord(): Payment | null {
    return Payment.query()
      .withAllRecursive()
      .whereId(+this.id)
      .first();
  }
}
export default PaymentDetail;
