
import { Component, Watch, Vue } from "vue-property-decorator";
import { PaymentList } from "./PaymentList.vue";
import {
  PaymentFilterQueryPayload,
  PaymentAllPayload,
  Payment,
} from "@/store/modules";
import { CDataTableSortVal } from "@/@types";
import { queryFilterHasChange, filterEmptyQuery } from "@/utility";
import { SortQueryPayload } from "@/store/modules";
import PaymentStatusDropdown from "./forms/PaymentStatusDropdown.vue";

@Component({
  components: { PaymentList, PaymentStatusDropdown },
})
export default class PaymentListHandler extends Vue {
  limit = 25;

  colFilter: PaymentFilterQueryPayload = {};

  currentPage = 1;

  sort: SortQueryPayload = {
    sort: "created_at",
    order: "desc",
  };

  sortVal: CDataTableSortVal = {};

  filterStatus!: string[];

  @Watch("colFilter")
  onColFilterChange(
    val: PaymentFilterQueryPayload | null,
    oldVal: PaymentFilterQueryPayload | null
  ) {
    if (!val || !oldVal) {
      return;
    }

    if (queryFilterHasChange<PaymentFilterQueryPayload>(val, oldVal)) {
      this.load();
    }
  }

  @Watch("limit")
  onLimitChange(val: number, oldVal: number) {
    if (val !== oldVal) {
      this.load();
    }
  }

  @Watch("sortVal")
  onSortValChange(val: CDataTableSortVal, oldVal: CDataTableSortVal) {
    this.sort = {
      sort: val.column || this.sort.sort || "",
      order: val.asc ? "asc" : "desc",
    };
    this.load();
  }

  mounted() {
    this.load();
  }

  load() {
    this.$nextTick(() => this.fetchRender(this.compilePayload()));
  }

  async fetchRender(payload: PaymentAllPayload) {
    await Payment.dispatch("all", payload);
  }

  get items(): Array<any> {
    const data = Payment.query()
      .orderBy(this.sort.sort || "created_at", this.sort.order)
      .withAll()
      .get();
    return data;
  }

  get loading(): boolean {
    return !!Payment.store().state.entities.payment.fetching;
  }

  get total() {
    return Payment.store().state.entities.payment.total;
  }

  get numPages(): number {
    const total = Payment.store().state.entities.payment.total;

    if (!total) {
      return 0;
    }
    // console.log('t', Math.ceil(total / this.limit), 1);
    return Math.max(Math.ceil(total / this.limit), 1);
  }

  onPageChange(page: number) {
    this.$nextTick(() =>
      this.fetchRender(
        this.compilePayload({
          offset: Math.floor(this.limit * (page - 1)),
        })
      )
    );
  }

  onStatusSelect(options: string[]) {
    this.filterStatus = options;
    this.load();
  }

  compilePayload(merge: Partial<PaymentAllPayload> = {}): PaymentAllPayload {
    return {
      limit: this.limit,
      offset: Math.floor(this.limit * (this.currentPage - 1)),
      ...this.sort,
      ...{ filter: this.compileFilter() },
      ...merge,
    };
  }

  compileFilter(): PaymentAllPayload["filter"] {
    const f = filterEmptyQuery(this.colFilter);

    if (this.filterStatus && this.filterStatus.length) {
      f.status = (this.filterStatus || [])
        .join(",");
    }

    return { ...f };
  }
}
