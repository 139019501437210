import Vue from 'vue'
import Component from 'vue-class-component'

@Component
export class PaymentErrorMixin extends Vue {
  hasError() {
    const errors = this.$store.state.entities.payment.error;
    return !(!errors || !errors.length);
  }
}

export default PaymentErrorMixin;