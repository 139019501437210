
import { Component, Prop, Watch } from "vue-property-decorator";
import { Payment } from "@/store/modules";
import { mixins } from "vue-class-component";
import { PaymentErrorMixin } from "./PaymentErrorMixin";
import { getModule } from "vuex-module-decorators";
import PaymentModule from "../../../store/modules/Payment/PaymentModule";
import { ErrorInterface } from "@/utility";

@Component
export class PaymentApproveButton extends mixins(PaymentErrorMixin) {
  @Prop(Payment)
  private record!: Payment;

  @Prop(Boolean)
  loading = false;

  savingText = "Saving";

  errorMsg = "";

  // saving = false;

  subscription: Array<() => void> = [];

  get isApproved() {
    // return false;
    return this.record.approved;
  }

  get text() {
    return this.record.approved ? "Disapprove" : "Approve";
  }

  // @Watch('saving')
  // test() {
  //   console.log(':(')
  // }

  // destroyed() {
  //   this.subscription.forEach((fn) => fn());
  // }

  // created() {
  //   const a = this.$store.watch<ErrorInterface[]>(
  //     () => Payment.getErrors(),
  //     (val, oldVal) => {
  //       if (val && val.length) {
  //         this.$root.$bvToast.toast(`Failed to save Payment`, {
  //           title: "Error",
  //           variant: "danger",
  //         });
  //       }
  //     }
  //   );
  //   const b = this.$store.watch<boolean>(
  //     () => {
  //       return Payment.isSaving();
  //     },
  //     (val) => {
  //       this.loading = val;
  //     }
  //   );
  //   this.subscription = [a, b];
  // }

  approve() {
    this.$bvModal
      .msgBoxConfirm(`Are you sure you want to ${this.text} this payment?`, {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true,
      })
      .then((state: boolean) => {
        if (!state) {
          return;
        }
        return this.$nextTick(() => this.update());
      });
  }

  async update() {
    // this.$root.$bvToast.toast(`Not supported yet`, {
    //   title: "Error",
    //   variant: "danger"
    // });
    // this.loading = false;
    // return;

    try {
      // await Payment.dispatch("toggleFlag", {
      //   id: this.record.id,
      //   flag: 'approve'
      // });

      // const payment = getModule(PaymentModule, this.$store);
      await Payment.dispatch("approve", {
        id: this.record.id,
      });
    } catch (err) {
      console.error("Error occured", err, this);
    }

    // if (!this.hasError()) {
    //   this.$root.$bvToast.toast(`Record has been updated`, {
    //     title: "Saving",
    //     variant: "success",
    //   });
    //   this.loading = false;
    //   return;
    // }

    // this.loading = false;
  }
}

export default PaymentApproveButton;
