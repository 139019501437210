
import { Component, Vue } from "vue-property-decorator";

@Component({
  components: {  }
})
export class PaymentReceiptPrint extends Vue {
}

export default PaymentReceiptPrint;
