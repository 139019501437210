
import { Component, Prop } from "vue-property-decorator";
import { Payment } from "@/store/modules";
import { mixins } from "vue-class-component";
import { PaymentErrorMixin } from "./PaymentErrorMixin";
import { ErrorInterface } from "@/utility";

@Component
export class PaymentVoidButton extends mixins(PaymentErrorMixin) {
  @Prop(Payment)
  private record!: Payment;

  @Prop(Boolean)
  loading = false;

  savingText = "Saving";

  get isVoided() {
    // return false;
    return this.record.voided;
  }

  get text() {
    return this.record.voided ? "Reinstate Voided" : "Void";
  }

  onClick() {
    this.$bvModal
      .msgBoxConfirm(`Are you sure you want to ${this.text} this payment?`, {
        title: "Please Confirm",
        size: "sm",
        buttonSize: "sm",
        okVariant: "danger",
        okTitle: "YES",
        cancelTitle: "NO",
        footerClass: "p-2",
        hideHeaderClose: true,
        centered: true,
      })
      .then((state: boolean) => {
        if (!state) {
          return;
        }
        return this.$nextTick(() => this.approvePayment());
      });
  }

  async approvePayment() {
    try {
      await Payment.dispatch("void", {
        id: this.record.id,
      });
    } catch (err) {
      console.error("Error occured", err, this);
    }
  }
}

export default PaymentVoidButton;
